import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {CookieService} from "ngx-cookie-service";
import {UserService} from "../../../../services/user.service";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  public userName: string;
  public profileImg: "assets/images/dashboard/profile.jpg";
  public user :any = {} ;
  public userGroup :any = {};

  constructor(public router: Router, private cookieService:CookieService , private userService:UserService)  {
    this.userService.getCurrentUserDetails();
  }


  ngOnInit() {

    this.user = this.userService.userDetails;
    this.userGroup  = this.user['groups'][0].name;

  }

  logoutFunc() {
    this.user = null
    this.cookieService.deleteAll();
    localStorage.clear();
    this.router.navigateByUrl('auth/login');
  }
}
